import {minTwoDigits} from "../../helpers";

export default class {

    constructor (settings = {}) {
        this.init();
    }

    /**
     * Initialization
     */
    init() {
        const imageSlider = new Swiper('.home-before_after__images-slider', {
            slidesPerView: 1,
            loop: true,
            observer: true,
            observeParents: true,
            navigation: {
                prevEl: '.home-before_after__images-slider__prev',
                nextEl: '.home-before_after__images-slider__next'
            },
            pagination: {
                el: '.home-before_after__images-slider__pagination',
                type: 'custom',
                renderCustom: (swiper, current, total) => {
                    return `
                        <span class="current">${minTwoDigits(current)}</span>
                        <span class="divider">-</span>
                        <span class="total">${minTwoDigits(total)}</span>
                    `;
                }
            }
        });

        const textSlider = new Swiper('.home-before_after__texts-slider', {
            observer: true,
            observeParents: true,
            slidesPerView: 1,
            loop: true
        });

        textSlider.controller.control = imageSlider;
        imageSlider.controller.control = textSlider;
    }

};