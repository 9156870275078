export default class {

    constructor (element) {
        this.settings = {
            photos: '.thumbnail-slider-photos',
            thumbs: '.thumbnail-slider-thumbs'
        };

        this.element = element;
        this.photos = this.element.querySelector(this.settings.photos);
        this.thumbs = this.element.querySelector(this.settings.thumbs);
        this.thumbsSwiper = null;

        this.init();
    }

    /**
     * Init thumbs slider
     */
    initThumbsSlider() {
        if (this.thumbs === null) throw new Error(`You need a slider with the class : ${this.settings.thumbs}`);
        this.thumbsSwiper = new Swiper(this.settings.thumbs, {
            slidesPerView: 2,
            spaceBetween: 12,
            loop: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            observer: true,
            observeParents: true,
            breakpoints: {
                800: {
                    slidesPerView: 3
                }
            }
        });
    }

    /**
     * Init photos slider
     */
    initPhotosSlider() {
        if (this.photos === null) throw new Error(`You need a slider with the class : ${this.settings.photos}`);
        if (!(this.thumbsSwiper instanceof Swiper)) throw new Error(`You need to have a swiper instance of your thumbs slider.`);

        new Swiper(this.settings.photos, {
            slidesPerView: 1,
            loop: true,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: '.thumbnail-slider__next'
            },
            thumbs: {
                swiper: this.thumbsSwiper
            }
        });
    }

    /**
     * Initialization
     */
    init() {
        this.initThumbsSlider();
        this.initPhotosSlider();
    }

};