import {isTouchDevice} from "../helpers";

let PDFViewer;

(function ($) {

    PDFViewer = class {

        constructor (el, settings = {}) {
            this.$el = $(el);
            this.pdfUrl = this.getPDFUrl();

            this.settings = {
                pdfViewerClass: 'pdf-viewer'
            };

            this.setupSettings(settings);
            this.init();
        }

        /**
         * Set up settings defined in the creation of the instance
         *
         * @param settings
         */
        setupSettings(settings) {
            if (Object.keys(settings).length > 0) {
                for (let key in settings) {
                    if (settings.hasOwnProperty(key) && this.settings.hasOwnProperty(key)) this.settings[key] = settings[key];
                }
            }
        }

        /**
         * Return the PDF Url or return false
         *
         * @returns {string, boolean}
         */
        getPDFUrl() {
            return this.$el.attr('data-pdf-url') ? this.$el.attr('data-pdf-url') : false;
        }

        /**
         * Get the PDF HTML5
         */
        PDFEmbed() {
            return (
                `
                    <embed src="${this.pdfUrl}" type="application/pdf" width="100%" height="100%" />
                `
            );
        }

        /**
         * Get the close button
         */
        PDFCloseButton() {
            return (
                `
                    <div class="${this.settings.pdfViewerClass}__close"></div>
                `
            );
        }

        /**
         * Get the PDF Viewer
         */
        PDFViewer() {
            return (
                `
                    <div class="${this.settings.pdfViewerClass}">
                        <div class="${this.settings.pdfViewerClass}__inner">
                            ${this.PDFCloseButton()}
                            ${this.PDFEmbed()}
                        </div>
                    </div>
                `
            );
        }

        /**
         * Open the PDF Viewer
         */
        openPDFViewer() {
            if ($(document).find(`.${this.settings.pdfViewerClass}`).length) $(document).find(`.${this.settings.pdfViewerClass}`).remove();
            $('body').append(this.PDFViewer());
        }

        /**
         * Close the PDF Viewer
         */
        closePDFViewer() {
            $(document).find(`.${this.settings.pdfViewerClass}`).remove();
        }

        /**
         * Bind all UI actions
         */
        bindUIActions() {
            // On click, open the PDF viewer
            this.$el.on('click', (function (e) {
                if (isTouchDevice()) return;
                e.preventDefault();
                this.openPDFViewer();
            }).bind(this));

            // On click on the close button, close the PDF Viewer
            $(document).on('click', `.${this.settings.pdfViewerClass}__close`, (function (e) {
                e.preventDefault();
                this.closePDFViewer();
            }).bind(this));

            // On click on the background of the pdf viewer, close the PDF Viewer
            $(document).on('click', `.${this.settings.pdfViewerClass}`, (function (e) {
                this.closePDFViewer();
            }).bind(this));
            $(document).on('click', `.${this.settings.pdfViewerClass}__inner`, (function (e) {
                e.stopPropagation();
            }).bind(this));

            // On ESC press, close PDF viewer
            $(document).keyup((function (e) {
                if (e.keyCode === 27) this.closePDFViewer();
            }).bind(this));
        }

        /**
         * Initialization
         */
        init() {
            if (this.pdfUrl !== false) this.bindUIActions();
        }

    };

}(jQuery));

export default PDFViewer;
