'use strict';

import smoothScrollPolyfill from './vendors/smoothscroll-polyfill';
import './vendors/doubleTapToGo';
import HomeBeforeAfterSlider from "./components/sliders/HomeBeforeAfterSlider";
import ThumbnailSlider from "./components/sliders/ThumbnailSlider";
import {fileUpload, textareaResize} from "./helpers";
import PDFViewer from "./components/PDFViewer";
import GumShoe from './vendors/gumShoe';
import BackToTop from "./components/BackToTop";
import FlashMessage from './components/flash-message';

let App;

(function ($) {

    App = class {

        constructor (settings = {}) {
            smoothScrollPolyfill.polyfill();
            this.settings = {};

            this.setupSettings(settings);

            this.init();
            this.validateForm();
            FlashMessage.initialPosition = 350;

        }

        /**
         * Set up settings defined in the creation of the instance
         *
         * @param settings
         */
        setupSettings(settings) {
            if (Object.keys(settings).length > 0) {
                for (let key in settings) {
                    if (settings.hasOwnProperty(key) && this.settings.hasOwnProperty(key)) this.settings[key] = settings[key];
                }
            }
        }

        /**
         * Active hover state on mobile device on first click, then on second click redirect
         */
        doubleTaps() {
            $('.home-specialities__card').doubleTapToGo();
        }

        /**
         * Set header height in a css variable
         */
        setHeaderHeight() {
            const {height} = document.querySelector('.header').getBoundingClientRect();
            document.documentElement.style.setProperty('--headerHeight', `${height}px`);
        }

        /**
         * Responsive menu
         */
        responsiveMenu() {
            let $menu = $('.header__menu .menu'),
                $overlay = $('.overlay-menu'),
                $toggler = $('.header__menu .hamburger');

            $toggler.on('click', function () {
                $(this).toggleClass('is-active');
                $menu.stop().slideToggle();
                $overlay.stop().fadeToggle();
            });

            $menu.find('li.menu-item-has-children > a').on('click', function (e) {
                e.preventDefault();
                $(this).parent().toggleClass('is-active');
                $(this).parent().find('.sub-menu').first().stop().slideToggle();
            });

            $menu.find('li.menu-item-has-children > a > span').on('click', function (e) {
                e.stopPropagation();
            });

            $overlay.on('click', function () {
                $(this).stop().fadeOut();
                $menu.stop().slideUp();
                $toggler.removeClass('is-active');
            });
        }
        validateForm() {
            const form = document.querySelector('#commentform');
            if (!form) {
                return;
            }
    
            const nameEL = document.querySelector('#author');
            const commentEL = document.querySelector('#comment');
            const emailEL = document.querySelector('#email');
            const isRequired = value => value === '' ? false : true;
            const isEmailValid = (email) => {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            };
            const showError = (formField) => {
                formField.parentElement.classList.remove('success');
                formField.parentElement.classList.add('error');
            };
            const showSuccess = (formField) => {
                formField.parentElement.classList.remove('error');
                formField.parentElement.classList.add('success');
            };
    
            const checkEmail = () => {
                let valid = false;
                const email = emailEL.value.trim();
                if (!isEmailValid(email)) {
                    showError(emailEL)
                } else {
                    showSuccess(emailEL);
                    valid = true;
                }
                return valid;
            };
    
            const checkUsername = () => {
                let valid = false;
                const name = nameEL.value.trim();
    
                if (!isRequired(name)) {
                    showError(nameEL);
                } else {
                    showSuccess(nameEL);
                    valid = true;
                }
                return valid;
            }
    
            const checkComment = () => {
                let valid = false;
                const comment = commentEL.value.trim();
    
                if (!isRequired(comment)) {
                    showError(commentEL);
                } else {
                    showSuccess(commentEL);
                    valid = true;
                }
                return valid;
            }
    
            form.setAttribute('novalidate', true);
    
            form.addEventListener("submit", (event) => {
                event.preventDefault();
    
                let isUsernameValid = checkUsername(),
                    isEmailValid = checkEmail(),
                    isCommentValid = checkComment();
    
                let isFormValid = isUsernameValid &&
                    isEmailValid &&
                    isCommentValid;
    
                if (isFormValid) {
                    const submitFormFunction = Object.getPrototypeOf(form).submit;
                    submitFormFunction.call(form);
                }
            });
        }
    
        /**
         * Init sliders (prepare for SwiperJS here);
         */
        initSliders() {
            if (typeof Swiper === 'undefined') return;
            let $wrapper = $('.swiper-wrapper'),
                $thumbnailSlider = $('.thumbnail-slider');

            // Set up swiper-slide class (needed for the plugin)
            $wrapper.each(function() {
                $(this).children().addClass('swiper-slide');
            });

            if ($('.home-before_after__images-slider').length) new HomeBeforeAfterSlider();
            if ($thumbnailSlider.length) {
                $thumbnailSlider.each(function () {
                    new ThumbnailSlider(this);
                });
            }
        }

        /**
         * Init anchor blocks
         */
        initAnchorBlocks() {
            if ($('.anchor-block').length && typeof GumShoe !== 'undefined') {
                new GumShoe('.anchor-block__links a', {
                    nested: true,
                    reflow: true,
                    offset: () => {
                    let defaultOffset  = 20,
                        $adminBar      = document.querySelector('#wpadminbar'),
                        adminBarHeight = (
                            $adminBar ? $adminBar.getBoundingClientRect().height : 0
                        ),
                        headerHeight   = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--headerHeight'));

                    if (!headerHeight) headerHeight = 0;

                    return defaultOffset + headerHeight + adminBarHeight;
                }
                });
            }
        }

        /**
         * Initialization of all modules
         */
        initModules() {
            this.initAnchorBlocks();
            this.initSliders();

            let $pdfViewers = $('[data-pdf-viewer="true"]');
            $pdfViewers.each(function () {
                new PDFViewer(this);
            });
        }

        /**
         * Initialization
         */
        init() {
            $(document).ready((function () {
                if (typeof $.fn.vcAccordion !== "undefined") $.fn.vcAccordion.Constructor.prototype.isAnimated = () => 0; // Remove VC tabs animations
                this.initModules();
                this.responsiveMenu();
                this.doubleTaps();
                textareaResize();
                fileUpload();
                $(window).on('load resize', this.setHeaderHeight);
                customElements.define('back-to-top', BackToTop);
            }).bind(this));
        }

    };
// Nouvelle section pour les fonctionnalités ajoutées
$(function () {
    /**
     * Ajout de focus et blur sur les inputs textarea
     */
    function inputFocused() {
        var textareas = document.querySelectorAll('.input-control input');

        function handleFocus() {
            if (!this.classList.contains('input--focused')) {
                this.classList.add('input--focused');
                this.parentNode.classList.add('input--active');
            }
        }

        function handleBlur() {
            if (this.value === '' && this.classList.contains('input--focused')) {
                this.classList.remove('input--focused');
                this.parentNode.classList.remove('input--active');
            }
        }

        for (var i = 0; i < textareas.length; i++) {
            textareas[i].addEventListener('focus', handleFocus);
            textareas[i].addEventListener('blur', handleBlur);
        }
    }

    /**
     * Validation du formulaire de commentaires
     */
    function validateForm() {
        const form = document.querySelector('#comment-form');
        if (!form) return;
    
        const nameEL = document.querySelector('#author');
        const emailEL = document.querySelector('#email');
        const commentEL = document.querySelector('#comment');
    
        const isRequired = value => value.trim() !== '';
        const isEmailValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    
        const showError = formField => {
            formField.parentElement.classList.remove('success');
            formField.parentElement.classList.add('error');
        };
    
        const showSuccess = formField => {
            formField.parentElement.classList.remove('error');
            formField.parentElement.classList.add('success');
        };
    
        const validateField = (field, validator) => {
            const value = field.value.trim();
            if (!validator(value)) {
                showError(field);
                return false;
            } else {
                showSuccess(field);
                return true;
            }
        };
    
        const checkUsername = () => validateField(nameEL, isRequired);
        const checkEmail = () => validateField(emailEL, isEmailValid);
        const checkComment = () => validateField(commentEL, isRequired);
    
        form.setAttribute('novalidate', true);
    
        form.addEventListener('submit', event => {
            event.preventDefault();
    
            const isUsernameValid = checkUsername();
            const isEmailValid = checkEmail();
            const isCommentValid = checkComment();
    
            if (isUsernameValid && isEmailValid && isCommentValid) {
                form.submit();
            }
        });
    }
    
    inputFocused();
    validateForm();
});

}(jQuery));

new App();